<template>
	<div class="padding-navbar w-full flex font-roboto overflow-auto">
		<form
			class="m-5 w-75 flex-box h-full bg-white rounded border border-gray-400 p-4 mx-auto"
			@submit.prevent="onSubmit"
		>
			<div class="mb-2 flex flex-col ">
				<label for="provider" class="font-nunito font-medium">
					Select provider to notify
				</label>
				<multiselect
					ref="multiselect"
					v-model="provider"
					track-by="id"
					label="label"
					placeholder="Select a provider"
					:options="providers"
					:multiple="false"
					:select-label="''"
					:selected-label="''"
				>
					<template slot="noResult">
						No providers found
					</template>
				</multiselect>
			</div>
			<div class="mb-2 flex flex-col">
				<label for="callback" class="font-nunito font-medium">
					Callback Number
				</label>
				<input
					v-model="callbackNumber"
					required
					type="text"
					name="callback"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter callback number"
				/>
			</div>
			<div class="mb-2 flex flex-col">
				<label for="name" class="font-nunito font-medium">
					Patient Name
				</label>
				<input
					v-model="name"
					required
					type="text"
					name="name"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter patient name"
				/>
			</div>

			<div class="flex flex-col">
				<label for="message" class="font-nunito font-medium">
					Patient Message
				</label>
				<textarea
					v-model="message"
					required
					name="message"
					placeholder="Enter patient message"
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-4 font-roboto"
				/>
			</div>
			<div class="mb-3 flex flex-col">
				<label for="dob" class="font-nunito font-medium">
					Patient DOB (Optional)
				</label>
				<input
					v-model="dob"
					type="text"
					name="dob"
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
					placeholder="Enter patient DOB"
				/>
			</div>
			<div class="mb-2 flex justify-end ">
				<button type="submit" class="btn btn-outline-success ml-2">
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'paper-plane']"
					/>
					<span class="btn-label">
						Send
					</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Multiselect,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all the providers in the needed format.
		 *
		 * @return {Array}
		 */
		providers() {
			if (!this.internalProviders) {
				return []
			}

			return this.internalProviders.map(provider => {
				return {
					id: provider.id,
					label: provider.full_name,
				}
			})
		},

		...mapGetters({
			partner: 'partners/active',
			internalProviders: 'providers/internal',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			try {
				const response = await this.create({
					provider_id: this.provider.id,
					callback_number: this.callbackNumber,
					patient_name: this.name,
					patient_message: this.message,
					patient_dob: this.dob,
					partner_id: this.$store.state.partners.active,
				})

				const { sid } = response.get('call')

				this.$router.push({
					name: 'app.paging.show',
					params: { sid },
				})

				this.$alert.success('pages.create.success')
			} catch (e) {
				this.$alert.error('pages.create.error')
			}
		},

		...mapActions({
			create: 'pages/create',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Create',

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.message = this.partner?.paging_template || ''
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			callbackNumber: null,
			dob: '',
			message: '',
			name: '',
			provider: null,
		}
	},
}
</script>
